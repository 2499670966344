* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'SourceSansPro Regular', 'PT Sans', sans-serif;
}

@font-face {
  font-family: 'SourceSansPro Regular';
  src: local('SourceSansPro Regular'), url(./fonts/source-sans-pro-v21-latin-ext_latin-regular.woff2) format('woff2');
}