.content-heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.3;
  font-weight: 600;
  color: #f7f8fa;
}

.content-subheading {
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.content-subtitle {
  max-width: 1080px;
  margin-bottom: 35px;
  line-height: 1.7;
  hyphens: auto; 
  text-align: justify;
  font-size: 18px;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .content-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}
